import {SalesTaxWorkflowStatus} from "@/constants/SalesTaxConstants";

export default class SalesTaxWorkflowFilter {
    companyId?: number;
    onlyCompleted?: boolean;
    status?: SalesTaxWorkflowStatus;
    state?: string;
    assigneeId?: number;
    hasUnprocessedStatements?: boolean;
    expired?: boolean;
    aboutToExpire?: boolean;
    pageNumber?: number = 1;
    pageSize?: number = 20;
    orderField?: OrderField = OrderField.ID;
    orderDirection?: 'ASC' | 'DESC' = 'DESC';

    constructor(init?: Partial<SalesTaxWorkflowFilter>) {
        Object.assign(this, init);
    }

}

export enum OrderField {
    ID = "ID",
    STATE = "STATE",
    EXPIRATION_DATE = "EXPIRATION_DATE"
}
