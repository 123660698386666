









































import {Component, Vue} from 'vue-property-decorator';
import Workspaces from "@/state/Workspaces";
import SalesTaxService from "@/services/SalesTaxService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {SalesTaxWorkflow} from "@/dto/salestax/SalesTaxWorkflowDTO";
import {SalesTaxBankStatementStatus, SalesTaxFilingPeriod} from "@/constants/SalesTaxConstants";
import RouteNames from "@/router/RouteNames";
import SalesTaxWorkflowFilter from "@/dto/salestax/SalesTaxWorkflowFilter";
import PaginationComponent from "@/components/util/PaginationComponent.vue";

const AppModule = namespace("App");

@Component({
  components: {PaginationComponent}
})
export default class SalesTaxWorkflowList extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private message = "";
  private successful = true;

  private filter = new SalesTaxWorkflowFilter();

  private workflows: Array<SalesTaxWorkflow> = [];

  totalPages = 1;

  mounted() {
    this.message = "";
    this.initFilter();
    this.loadWorkflows();
  }

  initFilter() {
    this.filter = new SalesTaxWorkflowFilter();
    if (Workspaces.isCurrentWorkspaceCompany) {
      this.filter.companyId = Workspaces.getCurrent?.id;
    }
  }

  loadWorkflows() {
    this.startLoading();
    return SalesTaxService.getWorkflows(this.filter).then(
        response => {
          this.workflows = response.data.content;
          this.totalPages = response.data.totalPages;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  calculatePeriod(wf: SalesTaxWorkflow): string {
    const expirationDate = new Date(wf.expirationDate);
    const year = expirationDate.getFullYear();
    const month = expirationDate.getMonth() + 1; // JavaScript months are 0-indexed

    switch (wf.filingPeriod) {
      case SalesTaxFilingPeriod.ANNUAL:
        return `${year - 1} year`;
      case SalesTaxFilingPeriod.QUARTERLY:
        return `${year} ${this.getQuarter(month - 1)} quarter`;
      case SalesTaxFilingPeriod.SEASONLY:
        return `${year} ${this.getSeason(month - 1)}`;
      case SalesTaxFilingPeriod.MONTHLY:
        return `${this.getMonthName(month - 1)} ${year}`;
      default:
        return "Unknown period";
    }
  }

  getQuarter(month: number): string {
    if (month <= 3) return "1st";
    if (month <= 6) return "2nd";
    if (month <= 9) return "3rd";
    return "4th";
  }

  getSeason(month: number): string {
    if (month <= 2 || month === 12) return "winter";
    if (month <= 5) return "spring";
    if (month <= 8) return "summer";
    return "autumn";
  }

  getMonthName(month: number): string {
    return new Intl.DateTimeFormat('en-US', {month: 'long'}).format(new Date(2022, month - 1));
  }

  isExpired(wf: SalesTaxWorkflow): boolean {
    const currentDate = new Date();
    const expirationDate = new Date(wf.expirationDate);

    return expirationDate < currentDate;
  }

  goToWorkflow(w: SalesTaxWorkflow) {
    this.$router.push({
      name: RouteNames.SALES_TAX_WORKFLOW,
      params: {
        companyId: `${w.customer.id}`,
        workflowId: `${w.id}`
      }
    })
  }

  countUnprocessedStatements(w: SalesTaxWorkflow) {
    return w.bankStatements.filter(s => [SalesTaxBankStatementStatus.UPLOADED, SalesTaxBankStatementStatus.BEING_PROCESSED].includes(s.status)).length;
  }

  changePage(page: number) {
    this.filter.pageNumber = page;
    this.loadWorkflows();
  }

}
